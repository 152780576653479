import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import axios from 'axios'
import { getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const getSingleTokenPrice = async (address) => {
  const url = `https://api.knightswap.financial/api/v2/assets/${address}`
  let price = 0
  try {
    const resp = await axios.get(url)
    price = resp.data.data.price_usd
  } catch (err) {
    console.error('getSingleTokenPrice', err)
  }


  return price
}

const getLPTokenPrice = async (name, address) => {
  const data = {
    query: ` {
    pair(id:"${address.toLowerCase()}"){
      reserveUSD
      totalSupply
    }
  }`,
  }

  let price = 0
  try {
    const resp = await axios({
      url: 'https://api.thegraph.com/subgraphs/name/knightswap-dex/knightgraph',
      method: 'post',
      data,
    })

    if (name === 'THUNDER-BNB LP ') {
      price = Number(resp.data.data.pair.totalSupply) / Number(resp.data.data.pair.reserveUSD)
    } else {
      price = Number(resp.data.data.pair.reserveUSD) / Number(resp.data.data.pair.totalSupply)
    }
  } catch (err) {
    console.error('getLPTokenPrice', err)
  }

  return price
}

const getTokenPrice = async (name, address, isLP) => {
  if (isLP) {
    return getLPTokenPrice(name, address)
  }
  return getSingleTokenPrice(address)
}

const getLpData = async (address) => {
  const query = `{

    pairDayDatas(
      orderBy:date,
      orderDirection:desc,
      first:1,
      where:{pairAddress:"${address.toLowerCase()}"}) {
      date
      dailyVolumeUSD
      reserveUSD
    }
  }
  `

  const apiResponse = await axios.post(
    'https://api.thegraph.com/subgraphs/name/knightswap-dex/knightgraph',
    {
      query,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  const data = apiResponse.data.data.pairDayDatas[0]
  return { dailyVolume: Number(data.dailyVolumeUSD), totalLiquidity: Number(data.reserveUSD) }
}

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const pricePerToken = await getTokenPrice(
        farmConfig.lpSymbol,
        farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
        !farmConfig.isTokenOnly,
      )


      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls)

      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
        if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
      }

      const [info, totalAllocPoint, KnightPerBlock] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'KnightPerBlock',
        },
      ])

      let totalLiquidity = 0
      let dailyVolume = 0
      if (!farmConfig.isTokenOnly) {
        try {
          const resp = await getLpData(lpAdress)
          totalLiquidity = resp.totalLiquidity
          dailyVolume = resp.dailyVolume
        } catch (err) {console.error(err)}
      }
      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        KnightPerBlock: new BigNumber(KnightPerBlock).toNumber(),
        pricePerToken,
        totalLiquidity,
        dailyVolume,
      }
    }),
  )
  return data
}

export default fetchFarms
