import { AbiItem } from 'web3-utils'
import { Interface } from '@ethersproject/abi'
import { getWeb3 } from 'utils/web3'
import MultiCallAbi from 'config/abi/Multicall.json'
import { getMulticallAddress } from 'utils/addressHelpers'
import ProtocolABI from 'config/abi/ProtocolABI.json'
import BigNumber from 'bignumber.js'

interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  params?: any[] // Function params
}

const multicall = async (abi: any[], calls: Call[]) => {
  const web3 = getWeb3()
  const multi = new web3.eth.Contract((MultiCallAbi as unknown) as AbiItem, getMulticallAddress())
  const itf = new Interface(abi)

  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const { returnData } = await multi.methods.aggregate(calldata).call()
  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}



export const lendingProtocal = async () => {
  const web3 = getWeb3()
  const multi = new web3.eth.Contract((ProtocolABI as unknown) as AbiItem, "0x9e6f94292ED59284377eabE69D29a810C19CBfE9")

  const returnData= await multi.methods.viewLendingNetwork("0x4f92913b86d5e79593fa2e475a8232b22ef17ed1").call()
  return new BigNumber(returnData.totalSupply).div(1e18)
  // const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  // return res
}



export default multicall
