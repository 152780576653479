import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'KNIGHT ',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0xE50cb76A71b0c52Ab091860cD61b9BA2FA407414',
    },
    rewardTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    poolCategory: PoolCategory.CORE,
    projectLink: '#',
    harvest: true,
    tokenPerBlock: '0.0096',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
      lpAddress: '0xd71710c21FBa293eB12E0CA079Fe37f50AD17d42',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },
  {
    sousId: 1,
    tokenName: 'Wizard',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0xe790a0683b669089AdC199996F89Bd40FEd4C559',
    },
    rewardTokenAddress: '0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18',
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://wizard.financial/',
    harvest: true,
    tokenPerBlock: '0.0096',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
      lpAddress: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },

  {
    sousId: 2,
    tokenName: 'Guard',

    stakingTokenName: QuoteToken.KNIGHT,
    rewardTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x3b8B92D882127b5e14c9476615374a69e55d4Ca1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.2054',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 3,
    tokenName: 'Crush',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x83e6fd11fdfbcd8b733bf69bc347fcbe7e32f89c',
    },
    rewardTokenDetails: {
      tokenAddress: '0x0Ef0626736c2d484A792508e99949736D0AF807e',
      lpAddress: '0x27E9d5a00fFc38D2EEf443B9Cd83e3bBa0c2D23b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    rewardTokenAddress: '0x0ef0626736c2d484a792508e99949736d0af807e',

    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bitcrush.com/',
    harvest: true,
    tokenPerBlock: '0.15625',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 4,
    tokenName: 'DEP',
    rewardTokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xB94ED8Bac55E7eE659F2175394413828432A2b0E',
    },
    rewardTokenDetails: {
      tokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',
      lpAddress: '0x17498e15d36ca99790998a1e43e89921c855a7eb',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dea.sg/',
    harvest: true,
    tokenPerBlock: '2.46398',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 5,
    tokenName: 'DSG',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xE7E7EA7CDcC59725812724341B3ef7E0E8CD92DD',
    },
    rewardTokenDetails: {
      tokenAddress: '0x9A78649501BbAAC285Ea4187299471B7ad4ABD35',
      lpAddress: '0x58d59997B112A8e854921d63107816037C58e709',
      quoteTokenAddress: '0x55d398326f99059ff775485246999027b3197955',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dsgmetaverse.com/#/',
    harvest: true,
    tokenPerBlock: '0.048605',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 6,

    tokenName: 'PETS',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x173Ad576358B959F44B6a5b661ef62e663841859',
    },
    rewardTokenDetails: {
      tokenAddress: '0xA77346760341460B42C230ca6D21d4c8E743Fa9c',
      lpAddress: '0x3bfE1FFaB34Ad671681E6F3F5D1Ee65f70ddD63f',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://micropets.io/',
    harvest: true,
    tokenPerBlock: '6757.372',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 7,
    tokenName: 'SING',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x7e6420c46326269f3c66fb180151df8c9d6e82f3',
    },
    rewardTokenDetails: {
      tokenAddress: '0x23894c0ce2d79b79ea33a4d02e67ae843ef6e563',
      lpAddress: '0x31904D0a2703292FE68DEb599f3d0462e7DcE10B',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://singular.farm/',
    harvest: true,
    tokenPerBlock: '0.0758',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },
  {
    sousId: 8,
    tokenName: 'PixelSQUID',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xE548FcACC9A23a3c61b158Fd3F50647E6ff3bA10',
    },
    rewardTokenDetails: {
      tokenAddress: '0x5a549cb605f1e651200c22d1a8482929b36330f7',
      lpAddress: '0xcaaa9a8fbbd90dbd0f46746c767e66e6db2e7517',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pixelsquid.site/',
    harvest: true,
    tokenPerBlock: '7.65',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x5a549cb605f1e651200c22d1a8482929b36330f7',
  },
  {
    sousId: 9,
    tokenName: 'MetaFish',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x2e62ef12b2837143d85b624ce045bb7337971bf3',
    },
    rewardTokenDetails: {
      tokenAddress: '0xe15176bcb7633be736e94ab6aa2e57a7ed5988ab',
      lpAddress: '0xa98dabB9CDd26df6425Cc4DE3129b37E42323bDa',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://metafish.io/',
    harvest: true,
    tokenPerBlock: '1.1342592',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xe15176bcb7633be736e94ab6aa2e57a7ed5988ab',
  },
  {
    sousId: 10,
    tokenName: 'Crush(OLD)',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xF928BB46273043F98cc731CeFFc16A1ccC177707',
    },
    rewardTokenDetails: {
      tokenAddress: '0x0Ef0626736c2d484A792508e99949736D0AF807e',
      lpAddress: '0x27E9d5a00fFc38D2EEf443B9Cd83e3bBa0c2D23b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    rewardTokenAddress: '0x0ef0626736c2d484a792508e99949736d0af807e',

    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bitcrush.com/',
    harvest: true,
    tokenPerBlock: '0.15625',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 11,
    tokenName: 'MVC',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x2bea9a5d63dbf39e7dd4f293d854225a122d4f8e',
    },
    rewardTokenDetails: {
      tokenAddress: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',
      lpAddress: '0x870b5422a4048b4b4Fe0b561D2CAf5FA39D10C77',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    rewardTokenAddress: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',

    poolCategory: PoolCategory.CORE,
    projectLink: 'https://mvc.finance/',
    harvest: true,
    tokenPerBlock: '1936.85',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x80d04e44955aa9c3f24041b2a824a20a88e735a8',
  },

  {
    sousId: 12,

    tokenName: 'PixelSQUID',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xea5b711a484e592a959bff5fe4780de9a2888a09',
    },
    rewardTokenDetails: {
      tokenAddress: '0x5a549cb605f1e651200c22d1a8482929b36330f7',
      lpAddress: '0xcaaa9a8fbbd90dbd0f46746c767e66e6db2e7517',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pixelsquid.site/',
    harvest: true,
    tokenPerBlock: '7.65',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x5a549cb605f1e651200c22d1a8482929b36330f7',
  },

  {
    sousId: 13,
    tokenName: 'JOKE',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x044c1b220b9c5afdf9afc18e0a2a7f676d2c263c',
    },
    rewardTokenDetails: {
      tokenAddress: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
      lpAddress: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://joke.community/',
    harvest: true,
    tokenPerBlock: '113.832688',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },

  {
    sousId: 14,
    tokenName: 'SPARTANS',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x99dd137ac66f7cdde4e137b0b71b4f472c0d5df3',
    },
    rewardTokenDetails: {
      tokenAddress: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
      lpAddress: '0xd593d418ef3ad46ada7a6a7ee5f5f27be4873331',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://spartans.army/',
    harvest: true,
    tokenPerBlock: '83.59',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },

  {
    sousId: 15,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x91E9c73520CaF0364E335546dc1f19AbC38C6b7E',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.0154320988',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },

  {
    sousId: 16,

    tokenName: 'THUNDER',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xab2569de7fd5cd54f04e153aea4fe9aa263c92d2',
    },
    rewardTokenDetails: {
      tokenAddress: '0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
      lpAddress: '0xaB96dDEf915489FA0BB02c8B243A97a4f60D82aF',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.thethunderverse.com/',
    harvest: true,
    tokenPerBlock: '0.0154320988',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
  },

  {
    sousId: 17,
    tokenName: 'THUNDER',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x6bb22f7d2df5ff79f5e982842221bb5cdb48382b',
    },
    rewardTokenDetails: {
      tokenAddress: '0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
      lpAddress: '0xaB96dDEf915489FA0BB02c8B243A97a4f60D82aF',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.thethunderverse.com/',
    harvest: true,
    tokenPerBlock: '2.3',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 9,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
  },

  {
    sousId: 18,
    tokenName: 'PDM',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x5505C55d9A4b2B157ac045E8fA20237F39167Fdb',
    },

    rewardTokenDetails: {
      tokenAddress: '0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
      lpAddress: '0x2D7c07768ec68ad0D419004e30158C4Cdd8c0913',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.padmon.io/',
    harvest: true,
    tokenPerBlock: '4.34',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },

  {
    sousId: 19,

    tokenName: 'SPARTANS',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x1e5b40f4df0232f7611bcb55ed06719cd785d3e0',
    },
    rewardTokenDetails: {
      tokenAddress: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
      lpAddress: '0xd593d418ef3ad46ada7a6a7ee5f5f27be4873331',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://spartans.army/',
    harvest: true,
    tokenPerBlock: '1.01',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },

  {
    sousId: 20,
    tokenName: 'Wizard',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0x4178a1e37aad964f1748b9add90daf5abb5127af',
    },
    rewardTokenAddress: '0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18',
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://wizard.financial/',
    harvest: true,
    tokenPerBlock: '0.046',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
      lpAddress: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },

  {
    sousId: 21,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.KNIGHT,
    rewardTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xd25d5bbb74479a55fb3ee75c7cf4ada139cdbbb0',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.0625',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 22,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xecCf9be05c8E00477c10aD9F6506FF7be51E4Ea2',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.028',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },

  {
    sousId: 23,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xf8bd0302e8e0b652dcd67d92cf42fbc5afbdc127',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.03',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 24,

    tokenName: 'DEP',
    rewardTokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x267b48F40D95a007bF1cD0482D6Ac49d54B72d54',
    },
    rewardTokenDetails: {
      tokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',
      lpAddress: '0x17498e15d36ca99790998a1e43e89921c855a7eb',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dea.sg/',
    harvest: true,
    tokenPerBlock: '0.839',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 37,
    tokenName: 'KRONOS',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x3B40d8a500dAB3a08Bac298Bc9c3341396594F1c',
    },

    rewardTokenDetails: {
      tokenAddress: '0xbeC68a941feCC79E57762e258fd1490F29235D75',
      lpAddress: '0x0d5d4Ff487C47D60603F311615F40e71F65DBC52',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://kronosdao.ai/',
    harvest: true,
    tokenPerBlock: '0.0814',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },
  {
    sousId: 25,
    tokenName: 'JOKE',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x7830bb3d96c8b53ab1bbb6bcf5cb8b23bd95bc08',
    },
    rewardTokenDetails: {
      tokenAddress: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
      lpAddress: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://joke.community/',
    harvest: true,
    tokenPerBlock: '113.832688',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },

  {
    sousId: 26,

    tokenName: 'DLAUNCH',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x6F055ba471f7620d993190254FdB2565808edBE4',
    },
    rewardTokenDetails: {
      tokenAddress: '0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61',
      lpAddress: '0xf43C7e1b8010d38aA2C1bb1Fc766Edf83c55a109',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://defilaunch.finance/',
    harvest: true,
    tokenPerBlock: '0.0625',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 27,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x5a8553b63cbdf270a717263ecef5f50f75bdf4bc',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.0434',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },

  {
    sousId: 28,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x10231d90e6e5dba2e594E163B61fdBD1e98cd0aa',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.046',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 29,

    tokenName: 'DUST',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0xE36E4CdF538ab62d283d1AE49007EEdFA9020497',
      lpAddress: '0x462c6B1145FC40653817b60fBF8B85fabbc9378A',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x2a074eE20f8e978C93a35DF4789B9dB2A9050736',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: ' https://spacedust.financial/',
    harvest: true,
    tokenPerBlock: '6558',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 30,

    tokenName: 'FORGE',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x5138868ed1814be113227b8c6025cdc46d9d1d16',
      lpAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x7B404cbbFDc6766ac0d9b032CB9B87bFacFf2eb7',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://forgefinance.io/',
    harvest: true,
    tokenPerBlock: '4.66',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 31,

    tokenName: 'LIBERO',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
      lpAddress: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x98e4ebDB9c5AE7567F80FCF7ABF2f6bB50088151',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://libero.financial/',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 32,

    tokenName: 'LIBERO',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
      lpAddress: '0xeD91F14E67b78b81C1014BbcB207fF839fdA1840',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x4ec928f9bb32e3174cfd8ad632b0afd5e89c05a6',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://libero.financial/',
    harvest: true,
    tokenPerBlock: '4.62',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 33,

    tokenName: 'DRYP',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x620Fdd450766c439230e72dF06b5EB9ec4d7fc6b',
      lpAddress: '0x1aD530c247C9831bF452d72f0de7a38c72cEd1C9',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
      rewardTokenDecimals: 9,
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xdf4091c43380236fa6357af68d96dab4c9a7c5b4',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '1.17400',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 34,
    tokenName: 'PDM',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x4f4EDA18Fe5Bc1126ed70b058e18601593E5Ec2A',
    },

    rewardTokenDetails: {
      tokenAddress: '0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
      lpAddress: '0x2D7c07768ec68ad0D419004e30158C4Cdd8c0913',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.padmon.io/',
    harvest: true,
    tokenPerBlock: '12.25',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },

  {
    sousId: 35,
    tokenName: 'BPM',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xaefae1cfd0006656a75e6bbb265306e3e12fb682',
    },

    rewardTokenDetails: {
      tokenAddress: '0x35359f21Abdf0f2B6aE01bFb96814738B515111e',
      lpAddress: '0xF5E12427599d9A3E60007A10c25940a391354B1B',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '0.0949',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },

  {
    sousId: 36,
    tokenName: 'WOLFIES',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xB6083D0a5bE4BbED47B017663C8e061E4ed9B819',
    },

    rewardTokenDetails: {
      tokenAddress: '0x6ad2b6d5d8f96c8e581d3100c12878b2151a0423',
      lpAddress: '0x791E1b54118a66100d4A010e545421EBf5e09B96',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '86.8',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },

  {
    sousId: 38,
    tokenName: 'DEP',
    rewardTokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xD9ce156279E5403f39b270E0F43C6061A2162FcD',
    },
    rewardTokenDetails: {
      tokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',
      lpAddress: '0x17498e15d36ca99790998a1e43e89921c855a7eb',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dea.sg/',
    harvest: true,
    tokenPerBlock: '0.9606',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 39,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xBcAb281E9FdCE2464a8AF51666F7C5BBdBA9AA78',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.01929',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },

  {
    sousId: 40,
    tokenName: 'MVC',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xC89c5F083139D5093aD1Aa3646BFf1af12E83234',
    },
    rewardTokenDetails: {
      tokenAddress: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',
      lpAddress: '0x870b5422a4048b4b4Fe0b561D2CAf5FA39D10C77',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    rewardTokenAddress: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',

    poolCategory: PoolCategory.CORE,
    projectLink: 'https://mvc.finance/',
    harvest: true,
    tokenPerBlock: '16541.7816',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x80d04e44955aa9c3f24041b2a824a20a88e735a8',
  },

  {
    sousId: 41,
    tokenName: 'MARVIN ',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x65131a1692e88508653038e8678049cb3541d366',
    },
    rewardTokenDetails: {
      tokenAddress: '0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
      lpAddress: '0xd2f121065E0d3dA35a9A9Ec1e3D1b3adAc2692f8',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    rewardTokenAddress: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',

    poolCategory: PoolCategory.CORE,
    projectLink: 'http://marvininueth.com/',
    harvest: true,
    tokenPerBlock: '7128.803',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
  },

  {
    sousId: 42,
    tokenName: 'SPARTANS',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xdc09fd48af19c7223447cf4080e2a5da3577da93',
    },
    rewardTokenDetails: {
      tokenAddress: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
      lpAddress: '0xd593d418ef3ad46ada7a6a7ee5f5f27be4873331',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://spartans.army/',
    harvest: true,
    tokenPerBlock: '4.74400',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },

  {
    sousId: 43,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.KNIGHT,
    rewardTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x1ac1586ccf7beb9ab742b09658f1ce0b1a3f8cc8',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.00542',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 44,
    tokenName: 'Wizard',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0xdeb7a113e17c79b4187bb5e534bffc4ca93b7532',
    },
    rewardTokenAddress: '0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18',
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://wizard.financial/',
    harvest: true,
    tokenPerBlock: '0.0964',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
      lpAddress: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },

  {
    sousId: 45,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x66870dAab10c9DD0159597dD1C3Cf2Dfe8320740',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.0694444444',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },

  {
    sousId: 46,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x1b89f45f7cfeb64503398da5bad451f1845b86e0',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.006371527',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 47,
    tokenName: 'JOKE',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x1c4d6d60d1297557a062e6f148dcb58866364cd3',
    },
    rewardTokenDetails: {
      tokenAddress: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
      lpAddress: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://joke.community/',
    harvest: true,
    tokenPerBlock: '306.75',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },





  {
    sousId: 48,
    tokenName: 'FORGE',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x5138868ed1814be113227b8c6025cdc46d9d1d16',
      lpAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x15e6549E648375cBF9b62FEcBB5871F209d66BDc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://forgefinance.io/',
    harvest: true,
    tokenPerBlock: '4.39800',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },


 

  {
    sousId: 49,

    tokenName: 'LIBERO',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
      lpAddress: '0x44bb33e6994651Fb9c4b6002a784036fAa40f73F',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xa74f3babfd30df346a9dd197ebbaf51673319733',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://libero.financial/',
    harvest: true,
    tokenPerBlock: '756.39',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },


  {
    sousId: 50,

    tokenName: 'WOLFIES',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xC8D79605eF3eD57a9ecF7c8abeb9C19b32dE88d1',
    },

    rewardTokenDetails: {
      tokenAddress: '0x6ad2b6d5d8f96c8e581d3100c12878b2151a0423',
      lpAddress: '0x791E1b54118a66100d4A010e545421EBf5e09B96',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '123.04',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
 
   },


   {
    sousId: 51,
    tokenName: 'DEP',
    rewardTokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x617aeBfDe668AFa8af24670141CeA2aC347De557',
    },
    rewardTokenDetails: {
      tokenAddress: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',
      lpAddress: '0x17498e15d36ca99790998a1e43e89921c855a7eb',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://dea.sg/',
    harvest: true,
    tokenPerBlock: '0.5513',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },





  {
    sousId: 52,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x7be47a7d517ee4642a93A7E7fe10330FeB991C9B',
    },
    poolCategory: PoolCategory.PARTNER,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.0069444',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 53,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xe23d7Bf81a69F187700fd43EDdFD10D1d946D080',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.PARTNER,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.069444',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },

  {
    sousId: 54,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xf89d74b01f556a2a03beed5f88e27ca7449e36c8',
    },
    rewardTokenDetails: {
      tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.007233',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },


  {
    sousId: 55,

    tokenName: 'FORGE',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x5138868ed1814be113227b8c6025cdc46d9d1d16',
      lpAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x3546fd028f6bf1bef7ad444e17ba131a30bd9cc0',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://forgefinance.io/',
    harvest: true,
    tokenPerBlock: '1.171875',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },




 
  {
    sousId: 56,
    tokenName: 'SPARTANS',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xbb10f837dc2d2cb09dabb14b1ab451f9a244da31',
    },
    rewardTokenDetails: {
      tokenAddress: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
      lpAddress: '0xd593d418ef3ad46ada7a6a7ee5f5f27be4873331',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://spartans.army/',
    harvest: true,
    tokenPerBlock: '3.83400',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
  },




  {
    sousId: 57,
    tokenName: 'Guard',

    stakingTokenName: QuoteToken.KNIGHT,
    rewardTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x6575539c2f29626a55385d3344caca53540c3cfb',
    },
    poolCategory: PoolCategory.PARTNER,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.03507',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },



  {
    sousId: 58,
    tokenName: 'Wizard',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0x5bE0ed851C0b1878D062c6dFD42467273808e3C7',
    },
    rewardTokenAddress: '0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18',
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://wizard.financial/',
    harvest: true,
    tokenPerBlock: '0.0462',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
      lpAddress: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },






  {
    sousId: 59,
    tokenName: 'BHC',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xfA01f2a31f261b91586BE67721b578ab92b56b79',
      56: '0x635C3B1535dA28f79ff610bEedC8FBf36Bbd1320',
    },
    rewardTokenAddress: '0x5066c68cae3b9bdacd6a1a37c90f2d1723559d18',
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://wizard.financial/',
    harvest: true,
    tokenPerBlock: '0.0001612654',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    rewardTokenDetails: {
      tokenAddress: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
      lpAddress: '0x7e1cCB2054fA447459dc3d9b40ee2921ed1045F6',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
  },


  {
    sousId: 60,
    tokenName: 'JOKE',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xC01bF9f0b4aa6ee07891292CFa0663E67761fF56',
    },
    rewardTokenDetails: {
      tokenAddress: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
      lpAddress: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://joke.community/',
    harvest: true,
    tokenPerBlock: '127.31800',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },



  {
    sousId: 61,
    tokenName: 'JOKE',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x3e2787c48ca88b850e42b48aab1eb8bff272c8d4',
    },
    rewardTokenDetails: {
      tokenAddress: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
      lpAddress: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
      rewardTokenDecimals: 9,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://joke.community/',
    harvest: true,
    tokenPerBlock: '115.0057',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },

  {
    sousId: 62,

    tokenName: 'FORGE',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x5138868ed1814be113227b8c6025cdc46d9d1d16',
      lpAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x9Be9c2e14E23fAC414D540b57B483bc8E82d7B2c',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://forgefinance.io/',
    harvest: true,
    tokenPerBlock: '1.09953',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    sousId: 63,

    tokenName: 'Guard',
    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    rewardTokenDetails: {
      tokenAddress: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      lpAddress: '0x5cdb2e08534ff052dee8989a33c5ea7aeebd4c6b',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x3cf7c2769d027F4324a42A60886308577eDBcf05',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.wolfdencrypto.com/?r_done=1',
    harvest: true,
    tokenPerBlock: '0.005787',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  
  {
    sousId: 64,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x7a94d6aC673Fdc6BC9e21F513aa7610f1D796C2B',
    },
    rewardTokenDetails: {
      tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      lpAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.0231481',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },



  {
    sousId: 65,
    tokenName: 'FRTNA',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0xc87570967234f25b5eda6c5e49e8c04242399e35',
    },
    rewardTokenDetails: {
      tokenAddress: '0x3949D773d188Bb53E67CAE443Aa8e741878b5216',
      lpAddress: '0x4Afb4997026465a2346f62893fcF81334C9CfFA8',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://fortunas.finance',
    harvest: true,
    tokenPerBlock: '0.985626',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
  },





  {
    sousId: 66,

    tokenName: 'FORGE',
    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xd23811058eb6e7967d9a00dc3886e75610c4abba',

    rewardTokenDetails: {
      tokenAddress: '0x5138868ed1814be113227b8c6025cdc46d9d1d16',
      lpAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x15e73d4FCB44C9A65ae84710fF1FDC6AfF75Ed37',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://forgefinance.io/',
    harvest: true,
    tokenPerBlock: '0.62162',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },





  {
    sousId: 67,
    tokenName: 'PDM',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x19B46477aC1d8b2FC212CA1EBa4752A17536387F',
    },

    rewardTokenDetails: {
      tokenAddress: '0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
      lpAddress: '0x2D7c07768ec68ad0D419004e30158C4Cdd8c0913',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.padmon.io/',
    harvest: true,
    tokenPerBlock: '1.58679',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },



  {
    sousId: 68,
    tokenName: 'WOLFIES',

    stakingTokenName: QuoteToken.KNIGHT,
    stakingTokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x598e4b09731a212eb2651bdac737909a01825949',
    },

    rewardTokenDetails: {
      tokenAddress: '0x6ad2b6d5d8f96c8e581d3100c12878b2151a0423',
      lpAddress: '0x791E1b54118a66100d4A010e545421EBf5e09B96',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BNB',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '',
    harvest: true,
    tokenPerBlock: '113.50800',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
  },


  {
    sousId: 69,
    tokenName: 'BUSD',

    stakingTokenName: QuoteToken.GUARD,
    stakingTokenAddress: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
    isCitadel: true,

    contractAddress: {
      97: '0xeb8A345ab74Ba098387a4ef18191C6D8793D9ae7',
      56: '0x026FB9C0357b46CE8C787Bf5541c9380a6c89b56',
    },
    rewardTokenDetails: {
      lpAddress: '0x28f8ED3Bb8795b11e9be8A6015aDd73ef7Cd3a14',
      tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      rewardTokenDecimals: 18,
      quoteTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      quoteTokenSymbol: 'BUSD',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://paxos.com/busd/',
    harvest: true,
    tokenPerBlock: '0.01388',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
  },

  

]

export default pools
