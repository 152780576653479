export default {
  cake: {
    56: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    97: '0x6A98694Af13cb554001b9Af4069529a850CaD663',
  },
  masterChef: {
    56: '0xE50cb76A71b0c52Ab091860cD61b9BA2FA407414',
    97: '0x84fd17d24C97E7170dCE4fdB1ce64613b9935014',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '0x309BDb11289692D7e64bF480adc4618A98325C12',
    97: '0x35DbA7c65513B5dAA3C839FBD0Ea0CCfa6948f22',
  },
  lotteryNFT: {
    56: '0x0b6d09404d734cdb4db918696030bdf277d8a1f4',
    97: '0xC927be778Ae9003ee1b16F877D94ce6de2537eb6',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47',
  },
  usd: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47',
  },
}
