import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://dex.knightswap.financial/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://dex.knightswap.financial/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  // {
  //   label: 'Nests',
  //   icon: 'PoolIcon',
  //   href: '/nests',
  // },

  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },

  {
    label: 'Knight Raids',
    icon: 'RaidIcon',
    href: '/raid',
    items: [
      {
        label: 'Citadel',
        href: '/citadel',
      },
      {
        label: 'Raids',
        href: '/raid',
      },
    ],
  },
  {
    label: 'IWO',
    icon: 'IWOIcon',
    href: '/iwo',
  },


  {
    label: 'Lending',
    icon: 'LendingIcon',
    href: 'https://lending.knightswap.financial/',
  },

  {
    label: 'Castle',
    icon: 'CastleIcon',
    href: 'https://knightcompounder.com/#/bsc',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  },
  {
    label: 'Audits',
    icon: 'AuditIcon',
    href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/November/KnightSwap.pdf',
  },
  {
    label: 'Listing',
    icon: 'HandshakeIcon',
    items: [
      {
        label: 'BscScan',
        href: 'https://bscscan.com/token/0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/exchanges/knightswap',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/knightswap',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/knightswap/',
      },
      {
        label: 'Gecko Terminal',
        href: 'https://geckoterminal.com/bsc/knightswap/pools',
      },
    ],
  },
  // {
  //   label: 'Analytics',
  //   icon: 'InfoIcon',
  //   href: '#',
  // },

  {
    label: 'Analytics',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: 'https://info.knightswap.financial',
      },
      {
        label: 'Tokens',
        href: 'https://info.knightswap.financial/tokens',
      },
      {
        label: 'Pairs',
        href: 'https://info.knightswap.financial/pairs',
      },
      {
        label: 'Accounts',
        href: 'https://info.knightswap.financial/accounts',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/Knightswap',
      },
      {
        label: 'Docs',
        href: 'https://knightatbsc.gitbook.io/',
      },
      {
        label: 'NFT Marketplace',
        href: 'https://www.wizard.financial/marketplace',
      },
    

      {
        label: 'Blog',
        href: 'https://medium.com/knight-bsc-dark-knight-ftm',
      },
      {
        label: 'Partnership',
        href: 'https://forms.gle/K7wsArETN45AAz6i9',
      },
    ],
  },

  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
