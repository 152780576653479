import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'KNIGHT',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xA12e1e2E2dea79694448aB9ef63bd4D82a26d90c',
    },
    tokenSymbol: 'KNIGHT',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    },
    isTokenOnly: true,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 0,
    risk: 5,
    lpSymbol: 'Knight-BUSD LP',
    lpAddresses: {
      97: '0xf633c7Fb7916eD97CD207cAEB3a46cEeA365396e',
      56: '0xA12e1e2E2dea79694448aB9ef63bd4D82a26d90c',
    },
    tokenSymbol: 'Knight',
    tokenAddresses: {
      97: '0x4E8733621cC3Ee2Da614485381C3b4A374921395',
      56: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'Knight-BNB LP',
    lpAddresses: {
      97: '0x69ef3F86cc1EA2844951D26A2373f00D2B717897',
      56: '0xd71710c21FBa293eB12E0CA079Fe37f50AD17d42',
    },
    tokenSymbol: 'Knight',
    tokenAddresses: {
      97: '0x4E8733621cC3Ee2Da614485381C3b4A374921395',
      56: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0x46F2cC2731f80Aa658B4408F9B533a0d19e9516E',
      56: '0x28f8ED3Bb8795b11e9be8A6015aDd73ef7Cd3a14',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 4,
    risk: 3,
    lpSymbol: 'Wizard-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x0525334A5AFa3170B3D621B7130C2b068BDF9e43',
    },
    tokenSymbol: 'Wizard',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 5,
    risk: 3,
    lpSymbol: 'Guard-BUSD LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xfC84B031a5221CED00b6470744aF5e5dA5710dDC',
    },
    tokenSymbol: 'GUARD',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 6,
    risk: 3,
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x0ddF35e107b6014B5577e87e3BBCECa74df3e09A',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 7,
    risk: 3,
    lpSymbol: 'BTCB-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xC71201ccE9d64B7C33E40FbeC058fA14597862fE',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 8,
    risk: 3,
    lpSymbol: 'DAI-USDT LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xaeFa492bbce96e8Cc1427511E21e7Ca1b172cb58',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    },
    quoteTokenSymbol: QuoteToken.USD,
    quoteTokenAdresses: contracts.usd,
  },

  {
    pid: 9,
    risk: 3,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x423496120476272e8ddffb72dff7586502beb43e',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 10,
    risk: 3,
    lpSymbol: 'CRUSH-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x27E9d5a00fFc38D2EEf443B9Cd83e3bBa0c2D23b',
    },
    tokenSymbol: 'CRUSH',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x0Ef0626736c2d484A792508e99949736D0AF807e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 3,
    lpSymbol: 'WOLFIE-BUSD LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xA59F9f3Cf28F5Ea5b4dB135E282fCC8157781846',
    },
    tokenSymbol: 'WOLFIE',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x46561C10bB934d7Ed4f82936DC7ed5f39b02558e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 12,
    risk: 3,
    lpSymbol: 'DEP-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x17498e15d36ca99790998a1e43e89921c855a7eb',
    },
    tokenSymbol: 'DEP',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xcaf5191fc480f43e4df80106c7695eca56e48b18',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 13,
    risk: 3,
    lpSymbol: 'PETS-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x3bfE1FFaB34Ad671681E6F3F5D1Ee65f70ddD63f',
    },
    tokenSymbol: 'PETS',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xA77346760341460B42C230ca6D21d4c8E743Fa9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 14,
    risk: 3,
    lpSymbol: 'SING-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x31904D0a2703292FE68DEb599f3d0462e7DcE10B',
    },
    tokenSymbol: 'SING',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x23894c0ce2d79b79ea33a4d02e67ae843ef6e563',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 15,
    risk: 3,
    lpSymbol: 'PixelSquid-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xcaaa9a8fbbd90dbd0f46746c767e66e6db2e7517',
    },
    tokenSymbol: 'PixelSQUID',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x5a549cb605f1e651200c22d1a8482929b36330f7',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 16,
    risk: 3,
    lpSymbol: 'MetaFish-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xa98dabB9CDd26df6425Cc4DE3129b37E42323bDa',
    },
    tokenSymbol: 'MetaFish',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xe15176bcb7633be736e94ab6aa2e57a7ed5988ab',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 17,
    risk: 3,
    lpSymbol: 'MVC-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x870b5422a4048b4b4Fe0b561D2CAf5FA39D10C77',
    },
    tokenSymbol: 'MVC',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x80d04e44955aa9c3f24041b2a824a20a88e735a8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 18,
    risk: 3,
    lpSymbol: 'JOKE-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x1EE17f3e9A96cC7DED524159b80a0955194B1f5f',
    },
    tokenSymbol: 'JOKE',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x2df0c13487efdf4eb7f6c042273b7aca781b29a0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 19,
    risk: 3,
    lpSymbol: 'SPARTANS-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xd593d418ef3ad46ada7a6a7ee5f5f27be4873331',
    },
    tokenSymbol: 'SPARTANS',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 20,
    risk: 3,
    lpSymbol: 'THUNDER-BNB LP ',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xaB96dDEf915489FA0BB02c8B243A97a4f60D82aF',
    },
    tokenSymbol: 'THUNDER',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 21,
    risk: 3,
    lpSymbol: 'PDM-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x2D7c07768ec68ad0D419004e30158C4Cdd8c0913',
    },
    tokenSymbol: 'PDM',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x4F890381abE9917A1cFeD7951B7Eb79900f4830D',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 22,
    risk: 3,
    lpSymbol: 'DLAUNCH-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xf43C7e1b8010d38aA2C1bb1Fc766Edf83c55a109',
    },
    tokenSymbol: 'DLAUNCH',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 23,
    risk: 3,
    lpSymbol: 'DUST-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x462c6B1145FC40653817b60fBF8B85fabbc9378A',
    },
    tokenSymbol: 'DUST',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xE36E4CdF538ab62d283d1AE49007EEdFA9020497',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 24,
    risk: 3,
    lpSymbol: 'FORGE-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
    },
    tokenSymbol: 'FORGE',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x5138868Ed1814Be113227b8c6025cdc46d9D1D16',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 25,
    risk: 3,
    lpSymbol: 'LIBERO-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xeD91F14E67b78b81C1014BbcB207fF839fdA1840',
    },
    tokenSymbol: 'LIBERO',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 26,
    risk: 3,
    lpSymbol: 'DRYP-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x1aD530c247C9831bF452d72f0de7a38c72cEd1C9',
    },
    tokenSymbol: 'DRYP',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x620Fdd450766c439230e72dF06b5EB9ec4d7fc6b',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 27,
    risk: 3,
    lpSymbol: 'BPM-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xF5E12427599d9A3E60007A10c25940a391354B1B',
    },
    tokenSymbol: 'BPM',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x35359f21Abdf0f2B6aE01bFb96814738B515111e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 28,
    risk: 3,
    lpSymbol: 'WOLFIES-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x791E1b54118a66100d4A010e545421EBf5e09B96',
    },
    tokenSymbol: 'WOLFIES',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x6ad2b6d5d8f96c8e581d3100c12878b2151a0423',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 29,
    risk: 3,
    lpSymbol: 'KRONOS-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x0d5d4Ff487C47D60603F311615F40e71F65DBC52',
    },
    tokenSymbol: 'KRONOS',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xbeC68a941feCC79E57762e258fd1490F29235D75',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 30,
    risk: 3,
    lpSymbol: 'FTM-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x495eA7F561450D367F55A273b5F31BA5Daf8C3B2',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0xad29abb318791d579433d831ed122afeaf29dcfe',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 31,
    risk: 3,
    lpSymbol: 'MARVIN-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xd2f121065E0d3dA35a9A9Ec1e3D1b3adAc2692f8',
    },
    tokenSymbol: 'MARVIN',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 32,
    risk: 3,
    lpSymbol: 'BUSD-USDC LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0xd215F5A4789fb2778d7f0EfCf58cA8f5322c8f8e',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 33,
    risk: 3,
    lpSymbol: 'BHC-BNB LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x7e1cCB2054fA447459dc3d9b40ee2921ed1045F6',
    },
    tokenSymbol: 'BHC',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },


  {
    pid: 34,
    risk: 3,
    lpSymbol: 'FTRNA-BUSD LP',
    lpAddresses: {
      97: '0x7FcFc7451D07DE9c8fc2b58467bb2b9cC9A9CB6e',
      56: '0x4Afb4997026465a2346f62893fcF81334C9CfFA8',
    },
    tokenSymbol: 'FTRNA',
    tokenAddresses: {
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      56: '0x3949D773d188Bb53E67CAE443Aa8e741878b5216',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  
]

export default farms
